<template>
  <div class="column-center">
    <img :src="icons.castle_banner" class="full-width" />

    <span class="castle-page-english" style="margin-top: 100px">Château de Swan</span>
    <span class="castle-page-title"> 关于斯万城堡 </span>
    <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px; margin-bottom: 25px" />

    <span class="castle-content castle-content-width" style="margin-bottom: 30px">
      西奈特里城堡位于沙特尔附近的伊利耶-公伯雷市，距离 “雷奥妮姑姑之家“ 100
      米左右，现被命名为“斯万城堡”。
      由著名作家、法中交流促进会会长、法国骑士勋章获得者亚丁先生买下，于2021年7月在普鲁斯特诞辰150周年之际落成。这个既是博物馆，又是艺术家居住的地方，更是激发作品《在斯万家旁边》人物故事之地。艺术史学家和音乐学家让-伊夫·帕特
      (Jean-Yves Patte) 负责重建 19
      世纪末布尔乔亚式城堡的家具，重创当年典型的氛围。每个房间、每件物品、每个细节都在讲述着一个个故事，它们让您从中发现马塞尔小说中的每个人物，体会他们的敏感，以及他们度过的时光。
      城堡周围有一片占地3公顷的园林，是一个适合思考和冥想最好不过的佳境。
    </span>

    <div class="column castle-content-width">
      <!-- <div class="castle-btn" style="margin-bottom: 25px">了解更多</div> -->
      <span class="castle-subtitle" style="margin-bottom: 10px">
        斯万城堡（Château de Swann ）：
      </span>

      <div class="row castle-content-width" style="align-items: flex-start">
        <img :src="icons.intro_triangle4" height="18" style="margin-right: 18px; margin-top: 12px" />
        <div class="column">
          <span class="castle-desc">
            地址：6 Rue Saint-Pierre, 28120 Illiers-Combray, France 法国
          </span>
          <span class="castle-desc">
            从巴黎开车至城堡：车程约1 小时 15分 - 从巴黎乘坐 TER ：约 2 小时，从沙特尔到伊利耶-公伯雷火车站约 25 分钟
          </span>
        </div>
      </div>

      <span class="castle-subtitle" style="margin-bottom: 10px; margin-top: 20px">
        开放时间：
      </span>

      <div class="row castle-content-width" style="align-items: flex-start">
        <img :src="icons.intro_triangle4" height="18" style="margin-right: 18px; margin-top: 12px" />
        <div class="column">
          <span class="castle-desc">
            周一至周五：10:00 - 12:00 / 14:00 - 17:00 无预约参观
          </span>
          <span class="castle-desc">
            周六至周日：10:00 - 12:00 / 14:00 - 16:00
          </span>
          <span class="castle-desc"> 请注意，PMP 目前安装中，暂不能使用 </span>
        </div>
      </div>

      <span class="castle-subtitle" style="margin-bottom: 10px; margin-top: 20px">
        参观票价 ：
      </span>

      <div class="row castle-content-width" style="align-items: flex-start">
        <img :src="icons.intro_triangle4" height="18" style="margin-right: 18px; margin-top: 12px" />
        <div class="column">
          <span class="castle-desc"> 全价8 欧元 / 12 岁以下儿童免费 </span>
          <span class="castle-desc"> 出示多人口家庭卡可享受 15% 的折扣 </span>
          <span class="castle-desc">
            学生、教师、求职者、60 岁以上的退休人员：6欧元
          </span>
        </div>
      </div>

      <span class="castle-subtitle" style="margin-bottom: 10px; margin-top: 20px">
        新闻联系人 ：
      </span>

      <div class="row castle-content-width" style="align-items: flex-start">
        <img :src="icons.intro_triangle4" height="18" style="margin-right: 18px; margin-top: 12px" />
        <div class="column">
          <span class="castle-desc"> Shanez Richert - ATIK </span>
          <span class="castle-desc">
            +33 (6) 10 50 44 44 - shanez@agence-atik.com
          </span>
          <span class="castle-desc">
            6 Rue Saint-Pierre, 28120 Illiers-Combray, France
          </span>
        </div>
      </div>

      <video style="width: 600px" :src="icons.castle_video" controls></video>

      <div style="height: 167px"></div>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style scoped>
.castle-content-width {
  width: 800px;
}
.castle-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.castle-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.castle-content {
  font-family: PingFangSC-Medium;
  font-size: 22px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 35px;
}

.castle-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}

.castle-subtitle {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}

.castle-desc {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}
</style>